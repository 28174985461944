.tile {
    text-align: center;
    margin: 10px 10px;
    height: 90px;
    width: 90px;
    border-radius: 10px;
    box-shadow: 0 0 10px 5px #e6e6e6;
    background-color: white;
    overflow: hidden;
    position: relative;
}

.tile .present-picture {
    max-width: 80px;
    max-height: 80px;
}

.badge {
    position: absolute;
    top: -7px;
    right: 0px;
    width: 25px;
    height: 25px;
    color: white;
    font-weight: 700;
    font-size: 15px;
    animation: tile-appear-grow 0.3s forwards;
}

.delete-badge {
    border-radius: 50%;
    background-color: #d33343;
}

@keyframes tile-appear-grow {
    from { opacity: 0; transform: scale(0.8); }
    to { opacity: 1; transform: scale(1); }
}

.frozen {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000033;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    /* border: 1px solid red; */
}

.grow {
    transform: scale(4);
    z-index: 1;
    transition: 0.1s;
}

/* .tile ion-button {
    position: absolute;
    width: 100%;
    height: 100%;
} */