@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delius+Swash+Caps&family=Sue+Ellen+Francisco&family=Walter+Turncoat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

.ion-content {
    font-family: Roboto;
}

.flex-center {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.absolute {
    position: absolute;
}

.fw {
    width: 100%;
}

.fh {
    height: 100%;
}

.logo .a {
    fill: #d33343;
}

.logo .b {
    fill: black;
}

.profile-photo {
    margin-top: 10px;
    border-radius: 50%;
}

/* ion-toolbar, ion-tab-bar {
    --border: 0!important;
    --border-width: 0!important;
} */

.bounce {
animation: bounce 5s infinite ease -3s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-30px);
  }
  85% {
    transform: translateY(0);
  }
  88% {
    transform: translateY(-7px);
  }
  91% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

.hidden {
    visibility: hidden;
}

ion-card {
  border-radius: 14px;
}

img {
  pointer-events: none;
}

/* ion-toolbar {
  --min-height: 80px;
} */