.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .frozen-presents {
    margin-top: 10px;
    width: 60%;
  }

  .alert-text {
    margin-bottom: 0px;
  }