@keyframes appear {
    0% {
        filter: blur(8px);
        transform: scale3d(0.8, 0.8, 0.8);
    }

    40% {
        filter: blur(3px);
        transform: scale3d(0.88, 0.88, 0.88);
    }

    100% {
        opacity: 1;
        filter: blur(0px);
        transform: scale3d(1,1,1);
    }
}

@keyframes grow {
    from { transform: scale3d(0.6,0.6,0.6); }
    to { transform: scale3d(0.8,0.8,0.8); }
}

.active {
    animation: appear 0.2s ease-in forwards;
}

.next {
    filter: blur(10px);
    animation: grow 0.2s ease-in forwards;
}

.card img {
    max-height: 100%;
    max-width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

ion-fab {
    right: -10px;
    top: -10px;
    transform:translateZ(2px);
}

.card-container {
    position: relative;
    max-height:max-content;
}

.card {
    overflow: hidden;
    flex-direction: column;
    width: 300px;
    height: 480px;
    margin: 0px;
}

.card .image-container {
    flex-grow: 1;
    background-color: white;
    overflow: hidden;
    flex-direction: column;
    padding: 10px;
}

.card .content {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}

.freeze-cover {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 14px;
    background-color: #00000033;
    transition: opacity 1s;
    backdrop-filter: blur(20px);
    opacity: 0;
}

.freeze-by-me {
    background-color: #c2ae1779;
    backdrop-filter: blur(2px);
}

.freeze-cover h1,
.freeze-cover img {
    transition: transform 1s;
    transform: scale(0.7);
}

.fc-active,
.fc-active h1,
.fc-active img {
    opacity: 1;
    transform: scale(1);
}

.fc-active {
    transform: translateZ(1px);
}

.freeze-cover h1 {
    width: 100%;
    text-align: center;
}

/* ion-card.sent-by-friend {
    box-shadow: #d33343dd 0px 4px 16px 2px;
} */